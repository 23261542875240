<template>
  <page-container title="维保组管理" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="维保组名称" prop="maintenancegroupname">
              <a-input v-model.trim="queryParams.maintenancegroupname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <!-- <a-form-model-item label="管理员账号" prop="adminaccount">
              <a-input v-model.trim="queryParams.adminaccount" placeholder="请输入"></a-input>
            </a-form-model-item> -->
            <a-form-model-item label="组长账号" prop="groupmenaccount">
              <a-input v-model.trim="queryParams.groupmenaccount" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="维保单位" prop="maintenancedepname">
              <a-input v-model="queryParams.maintenancedepname" placeholder="请输入"></a-input>
            </a-form-model-item>
<!--            <a-form-model-item label="维护单位" prop="maintenancedepid">-->
<!--              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions.filter(item => item.deptype=='3')" v-model="deptCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择区域" :change-on-select="true" :allow-clear="false"></a-cascader>-->
<!--            </a-form-model-item>-->
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">维保组列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="maintenancegroupid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="memberList" slot-scope="value" class="status-view">
            <span class="text" :title="(value && value.length) ? value.map(item => item.username).join(',') : ''">{{(value && value.length) ? value.map(item => item.username).join(',') : ''}}</span>
          </span>
          <span slot="operation" slot-scope="value, record, index">
            <a-button type="link" size="small" @click="showModal('detail', value, record, index)">详情</a-button>
            <a-button type="link" size="small" @click="showModal('edit', value, record, index)">修改</a-button>
            <a-button type="link" size="small" @click="deleteConfirm(value, record, index)">删除</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal v-model="modalVisible" :title="modalTitle" :bodyStyle="{maxHeight: '400px', overflow: 'auto'}">
      <template slot="footer">
        <a-button v-if="modalType=='add'||modalType=='edit'" @click="modalCancel">取消</a-button>
        <a-button type="primary" @click="modalConfirm">确认</a-button>
      </template>
      <a-form-model ref="modalForm" :model="formData" :rules="formRules" :label-col="{span:6}" :wrapper-col="{span:14}">
        <a-form-model-item label="维保组名称" prop="maintenancegroupname">
          <a-input v-model.trim="formData.maintenancegroupname" />
        </a-form-model-item>
        <a-form-model-item label="维保单位" prop="maintenancedepid">
          <a-cascader :show-search="{ filter }" :dropdownMatchSelectWidth=false :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions.filter(item => item.deptype=='3')" v-model="maintenancedepidList" :load-data="deptOnLoadChildren" placeholder="请选择维护单位" :change-on-select="true" :allow-clear="false"></a-cascader>
        </a-form-model-item>
<!--        <a-form-model-item label="维护单位" prop="maintenancedepname">-->
<!--          <a-input v-model="queryParams.maintenancedepname" placeholder="请输入"></a-input>-->
<!--        </a-form-model-item>-->
        <!-- <a-form-model-item label="维护组管理员" prop="adminUserShow">
          <a-input :value="formData.adminUserShow" disabled style="width: 180px;" />
          <a-button type="primary" @click="selectAdminVisible=true" style="margin-left: 10px;">请选择</a-button>
        </a-form-model-item> -->
        <a-form-model-item label="组长" prop="managerUserShow">
          <a-input :value="formData.managerUserShow" disabled style="width: 180px;" />
          <a-button type="primary" @click="selectManagerVisible=true" style="margin-left: 10px;">请选择</a-button>
        </a-form-model-item>
        <a-form-model-item label="组员" prop="groupMembersShow">
          <a-input :value="formData.groupMembersShow" disabled style="width: 180px;" />
          <a-button type="primary" @click="selectGroupMembersVisible=true" style="margin-left: 10px;">请选择</a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- <select-user key="selectadmin" :visible.sync="selectAdminVisible" @get-selected-rows="getAdminUser" select-type="radio" :default-selected="adminUser?[adminUser]:[]"></select-user> -->
    <select-user key="selectmanager" :visible.sync="selectManagerVisible" @get-selected-rows="getManagerUser" select-type="radio" :default-selected="managerUser?[managerUser]:[]" :params="selectUserParams"></select-user>
    <select-user key="selectmembers" :visible.sync="selectGroupMembersVisible" @get-selected-rows="getGroupMembers" :default-selected="groupMembers" :params="selectUserParams"></select-user>
  </page-container>
</template>

<script>
import deptselect from '@/mixins/deptselect'
import { getMentenacegroupListByCondition, addMentenacegroup, modifyMentenacegroup, deleteMentenacegroup, getMentenacegroupById, bindMentenacemember } from 'A/xtpz'
import { getItemFromArrayByKey, getTreeDeepString } from 'U'
import store from '@/store'
import SelectUser from '@/components/modals/SelectUser.vue'
import callCenter from "C/modals/callCenter";
import pagination from "@/mixins/pagination";
export default {
  name: 'maintenanceGroupManagement',
  mixins: [deptselect,pagination],
  components: {
    SelectUser
  },
  data() {
    return {
      showAdvanced: false,
      initDeptType: '3',
      breadcrumb: [
        {
          name: '基础功能管理',
          path: ''
        },
        {
          name: '基础信息管理',
          path: ''
        },
        {
          name: '维保组管理',
          path: ''
        },
      ],
      queryParams: {
        maintenancegroupname: '',
        // adminaccount: '',
        groupmenaccount: '',
        maintenancedepid: '',
        maintenancedepname:'',
      },
      tableColumns: [
        {
          title: '维保组名称',
          dataIndex: 'maintenancegroupname',
          key: 'maintenancegroupname',
          ellipsis: true,
        },
        // {
        //   title: '管理员',
        //   dataIndex: 'adminname',
        //   key: 'adminname',
        // },
        {
          title: '组长',
          dataIndex: 'groupmenname',
          key: 'groupmenname',
          ellipsis: true,
        },
        {
          title: '维保单位',
          dataIndex: 'maintenancedepname',
          key: 'maintenancedepname',
          ellipsis: true,
        },
        {
          title: '维保组成员',
          dataIndex: 'memberList',
          key: 'memberList',
          ellipsis: true,
          scopedSlots: { customRender: 'memberList' }
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: { customRender: 'operation' },
          width:70,
        },
      ],
      tableData: [],
      tableLoading: false,
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      },
      modalVisible: false,
      modalType: '',
      formData: {
        maintenancegroupid: '',
        maintenancegroupname: '',
        maintenancedepid: '',
        // adminUserShow: '',
        managerUserShow: '',
        groupMembersShow: '',
      },
      formRules: {
        maintenancegroupname: [{required: true, message: '请输入维保组名称'}],
        maintenancedepid: [{required: true, message: '请选择维保单位'}],
        // adminUserShow: [{required: true, message: '请选择管理员'}],
        managerUserShow: [{required: true, message: '请选择组长'}],
      },
      // selectAdminVisible: false,
      // adminUser: null,
      selectManagerVisible: false,
      managerUser: null,
      selectGroupMembersVisible: false,
      groupMembers: null,
      maintenancedepidList: [],
      counts:'',
    }
  },
  computed: {
    modalTitle() {
      let title = '';
      if(this.modalType == 'add') {
        title = '新增';
      }else if(this.modalType == 'detail') {
        title = '详情';
      }else if(this.modalType == 'edit') {
        title = '修改';
      }else {
        title = '';
      }
      return title;
    },
    selectUserParams() {
      return {
        userdepid: this.formData.maintenancedepid
      }
    }
  },
  watch: {
    maintenancedepidList(val) {
      if(val && val.length) {
        this.formData.maintenancedepid = val[val.length-1];
        this.$refs.modalForm.clearValidate('maintenancedepid');
      }else {
        this.formData.maintenancedepid = ''
      }
    },
    managerUser(val) {
      if(val) {
        this.formData.managerUserShow = val.username
        this.$refs.modalForm.clearValidate('managerUserShow');
      }else {
        this.formData.managerUserShow = ''
      }
    },
    groupMembers(val) {
      if(val && val.length) {
        let result = '';
        if(val && val.length) {
          val.forEach(item => {
            if(result) {
              result += ','
            }
            result += item.username
          })
        }
        this.formData.groupMembersShow = result
      }else {
        this.formData.groupMembersShow = ''
      }
    },
    deptCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.maintenancedepid = val[val.length-1];
      }else {
        this.queryParams.maintenancedepid = '';
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
  },
  methods: {
    filter(inputValue, path) {
      return path.some(option => option.userdepname.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
    init() {
      this.getTableData();
    },
    resetQueryParams() {
      this.deptCascaderSelected = [];
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      this.tableLoading = true;
      getMentenacegroupListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    onShowSizeChange(page, size) {
      this.pagination.pageSize = size;
      this.getTableData(true);
    },
    deleteConfirm(value, record) {
      this.$confirm({
        // title: '',
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(record.maintenancegroupid);
        },
        onCancel() {},
      });
    },
    delete(maintenancegroupid) {
      this.showLoading();
      if(maintenancegroupid) {
        let params = {
          maintenancegroupid
        };
        deleteMentenacegroup(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      this.modalType = type;
      if(type == 'add') {
        this.modalVisible = true;
        this.$nextTick(() => {
          this.$refs.modalForm.resetFields();
        })
      }else {
        this.modalVisible = true;
        this.showLoading();
        this.$nextTick(() => {
          let params = {
            maintenancegroupid: record.maintenancegroupid
          }
          getMentenacegroupById(params).then(res => {
            this.hideLoading();
            if(res && res.returncode == '0') {
              record = res.item;
              this.formData.maintenancegroupid = record.maintenancegroupid;
              this.formData.maintenancedepid = record.maintenancedepid;
              this.initDeptOptionsByUserdepid(record.maintenancedepid, 'maintenancedepidList')
              this.formData.maintenancegroupname = record.maintenancegroupname;
              // if(record.adminid) {
              //   this.adminUser = {
              //     userid: record.adminid,
              //     username: record.adminname,
              //   };
              // }else {
              //   this.adminUser = null;
              // }
              if(record.groupmenid) {
                this.managerUser = {
                  userid: record.groupmenid,
                  username: record.groupmenname,
                };
              }else {
                this.managerUser = null;
              }
              if(record.memberList && record.memberList.length) {
                this.groupMembers = record.memberList;
              }else {
                this.groupMembers = null;
              }
            }else {
              this.$message.error(res.errormsg||'查询失败');
            }
          })
        })
      }
    },
    modalCancel() {
      this.modalVisible = false;
    },
    resetModal() {
      this.$refs.modalForm.resetFields();
    },
    modalConfirm() {
      if(this.modalType == 'add' || this.modalType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if(valid) {
            this.addOrEdit();
          }else {
            return false;
          }
        })
      }else {
        this.modalVisible = false;
      }
    },
    addOrEdit() {
      this.showLoading();
      let memberids = '';
      if(this.groupMembers && this.groupMembers.length) {
        memberids = this.groupMembers.map(item => item.userid).join('|')
      }else if(this.formData.groupMembersShow) {
        memberids = this.formData.groupMembersShow
      }
      let params = {
        maintenancegroupname: this.formData.maintenancegroupname,
        maintenancedepid: this.formData.maintenancedepid,
        // adminid: this.adminUser ? this.adminUser.userid : this.formData.adminUserShow,
        groupmenid: this.managerUser ? this.managerUser.userid : this.formData.managerUserShow,
        memberids: memberids,
      };
      if(this.modalType == 'add') {
        addMentenacegroup(params).then(this.addOrEditResult);
      }else if(this.modalType == 'edit') {
        params.maintenancegroupid = this.formData.maintenancegroupid
        modifyMentenacegroup(params).then(this.addOrEditResult)
      }
    },
    addOrEditResult(res) {
      this.hideLoading();
      if(res && res.returncode == '0') {
        this.$message.success('操作成功');
        this.modalVisible = false;
        this.getTableData();
      }else {
        this.$message.error(res.errormsg||'操作失败');
      }
    },
    // getAdminUser(selectedRows) {
    //   this.adminUser = selectedRows[0];
    // },
    getManagerUser(selectedRows) {
      this.managerUser = selectedRows[0];
    },
    getGroupMembers(selectedRows) {
      this.groupMembers = selectedRows;
    }
  }
}
</script>